<template>
  <v-container fluid class="blue-grey lighten-5">
    <v-row>
      <v-col cols="12" xs="2" sm="2" md="2" lg="2">
        <v-card elevation="0" clipped-left class="ma-2">
          <v-navigation-drawer permanent>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title text-uppercase"> Reviews List </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list nav flat>
              <v-list-item
                v-for="year in years"
                :key="year"
                link
                @click="setyear(year)"
                class="subtitle-1 text-uppercase"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ year }} Review</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-card>
      </v-col>
      <v-col cols="12" xs="10" sm="10" md="10" lg="10" class="px-10">
        <v-row class="white pa-4 ma-3">
          <v-col>
            <p class="body-1 text-uppercase font-weight-bold indigo--text">Review Type</p>
            <h2 v-if="h1flag" class="display-1 font-weight-bold">Half-Yearly Review</h2>
            <h2 v-if="h2flag" class="display-1 font-weight-bold">Annual Review</h2>
          </v-col>
          <v-col>
            <p class="body-1 text-uppercase font-weight-bold indigo--text">Employee Name</p>
            <h2 class="display-1 font-weight-bold">{{ emppdpdata.name }}</h2>
          </v-col>
          <v-col>
            <p class="body-1 text-uppercase font-weight-bold indigo--text">YEAR</p>
            <h2 class="display-1 font-weight-bold">{{ emppdpdata.year }}</h2>
          </v-col>
          <v-col>
            <p class="body-1 text-uppercase font-weight-bold indigo--text">Status</p>
            <h2 class="display-1 font-weight-bold">{{ emppdpdata.reviewerstatus }}</h2>
          </v-col>
        </v-row>
        <v-row class="white pa-4 ma-3">
          <v-col cols="12">
            <h2 class="indigo--text text-uppercase">Objectives</h2>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <tiptap-vuetify
              v-if="objectivesflag"
              max-height="250px"
              v-model="emppdpdata.objectives"
              :extensions="extensions"
              :card-props="{ outlined: true }"
            />
            <v-card v-else outlined min-height="250" color="grey lighten-4">
              <v-card-text class="subtitle-1" v-html="emppdpdata.objectives"></v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="white pa-4 ma-3">
          <v-col cols="12">
            <h2 class="indigo--text text-uppercase">Mid-Year Review <v-icon v-if="!h1flag">mdi-lock</v-icon></h2>
          </v-col>
          <v-col cols="12" xs="4" sm="4" md="4" lg="4">
            <h4 class="title ml-2 indigo--text">Positives</h4>
            <tiptap-vuetify
              v-if="h1flag"
              max-height="350px"
              v-model="emppdpdata.h1positives"
              :extensions="extensions"
              :card-props="{ outlined: true }"
            />
            <v-card v-else outlined min-height="250" color="grey lighten-4">
              <v-card-text class="subtitle-1" v-html="emppdpdata.h1positives"></v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" xs="4" sm="4" md="4" lg="4">
            <h4 class="title ml-2 indigo--text">Improvements</h4>
            <tiptap-vuetify
              v-if="h1flag"
              max-height="350px"
              v-model="emppdpdata.h1improvements"
              :extensions="extensions"
              :card-props="{ outlined: true }"
            />
            <v-card v-else outlined min-height="250" color="grey lighten-4">
              <v-card-text class="subtitle-1" v-html="emppdpdata.h1improvements"></v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" xs="4" sm="4" md="4" lg="4">
            <h4 class="title ml-2 indigo--text">Comments</h4>
            <tiptap-vuetify
              class="mr-1"
              v-if="h1flag"
              max-height="350px"
              v-model="emppdpdata.h1comments"
              :extensions="extensions"
              :card-props="{ outlined: true }"
            />
            <v-card v-else outlined min-height="250" color="grey lighten-4">
              <v-card-text class="subtitle-1" v-html="emppdpdata.h1comments"></v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="white pa-4 ma-3">
          <v-col cols="12">
            <h2 class="indigo--text text-uppercase">Annual Review <v-icon v-if="!h2flag">mdi-lock</v-icon></h2>
          </v-col>
          <v-col cols="12" xs="4" sm="4" md="4" lg="4">
            <h4 class="title ml-2 indigo--text">Positives</h4>
            <tiptap-vuetify
              v-if="h2flag"
              max-height="350px"
              v-model="emppdpdata.h2positives"
              :extensions="extensions"
              :card-props="{ outlined: true }"
            />
            <v-card v-else outlined min-height="250" color="grey lighten-4">
              <v-card-text class="subtitle-1" v-html="emppdpdata.h2positives"></v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" xs="4" sm="4" md="4" lg="4">
            <h4 class="title ml-2 indigo--text">Improvements</h4>
            <tiptap-vuetify
              v-if="h2flag"
              max-height="350px"
              v-model="emppdpdata.h2improvements"
              :extensions="extensions"
              :card-props="{ outlined: true }"
            />
            <v-card v-else outlined min-height="250" color="grey lighten-4">
              <v-card-text class="subtitle-1" v-html="emppdpdata.h2improvements"></v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" xs="4" sm="4" md="4" lg="4">
            <h4 class="title ml-2 indigo--text">Comments</h4>
            <tiptap-vuetify
              class="mr-1"
              v-if="h2flag"
              max-height="350px"
              v-model="emppdpdata.h2comments"
              :extensions="extensions"
              :card-props="{ outlined: true }"
            />
            <v-card v-else outlined min-height="250" color="grey lighten-4">
              <v-card-text class="subtitle-1" v-html="emppdpdata.h2comments"></v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="white pa-4 ma-3">
          <v-col cols="12">
            <h2 class="indigo--text text-uppercase">Next Year ({{ parseInt(emppdpdata.year) + 1 }}) Objectives</h2>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <tiptap-vuetify
              v-if="h2flag"
              height="50px"
              max-height="250px"
              v-model="emppdpdata.nextyearobjectives"
              :extensions="extensions"
              :card-props="{ outlined: true }"
            />
            <v-card v-else outlined min-height="250" color="grey lighten-4">
              <v-card-text class="subtitle-1" v-html="emppdpdata.nextyearobjectives"></v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="white pa-4 ma-3" v-if="h1flag || h2flag">
          <v-btn v-if="saveenable" :disabled="!valid" text x-large class="success ml-2 my-2" @click="savepdp()">
            Save Review
          </v-btn>
          <div class="text-center" v-if="spinner">
            <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
          </div>
        </v-row>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar">
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="success" text v-bind="attrs" @click="snackbar = false"> Okay </v-btn>
      </template>
    </v-snackbar>
    <div v-if="alert">
      <v-alert text color="info">
        <v-row align="center" no-gutters>
          <v-col cols="12" xs="10" sm="10" md="10" lg="10">{{ alertmsg }}</v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn cols="12" xs="1" sm="1" md="1" lg="1" color="info" outlined @click="alert = false"> Okay </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>
  </v-container>
</template>

<script>
import { PDPRef } from "@/fb"
import { formMixin } from "@/mixins/formMixin.js"
import { emailMixin } from "@/mixins/emailMixin.js"
import { convertAll } from "@/mixins/convertall.js"
import { updateClientPDPMixin } from "@/mixins/updateClientPDPMixin.js"

import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify"
export default {
  name: "reviewpdp",
  mixins: [formMixin, emailMixin, convertAll, updateClientPDPMixin],
  components: { TiptapVuetify },
  data: () => ({
    PDP: [], // PDP array
    pageid: "",
    years: [],
    pdparray: [],
    emppdpdata: {},
    yearindex: -1,
    email: "",
    domain: "",
    extensions: [Underline, Italic, ListItem, BulletList, OrderedList, Bold, Paragraph, HardBreak],
    snackbar: false,
    snackbartext: "",
    alert: false,
    alertmsg: "",
    save: true,
    valid: true,
    h1flag: false,
    h2flag: false,
    objectivesflag: false,
    saveenable: false,
    spinner: false,
  }), // end of data
  async created() {
    this.pageid = this.$route.params.id
    console.log("inside reviewpdb created", this.pageid)
    this.editflag = true
    await this.initialize()
  },
  methods: {
    async initialize() {
      this.PDP = []
      console.log("inside initialize", this.pageid)
      await PDPRef.where("pdppageid", "==", this.pageid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.PDP.push({
              ...doc.data(),
              uid: doc.id,
            }) //end of array push
          }) // end of for each.
        }) // end if PDPRef

      console.log("pdp length", this.PDP.length)

      this.email = this.PDP[0].email
      this.domain = this.PDP[0].domain
      console.log("emp details", this.email, this.domain)

      if (this.PDP[0].pdp.length > 1) this.pdparray = this.PDP[0].pdp.sort((a, b) => b.year - a.year)
      else this.pdparray = this.PDP[0].pdp

      console.log("pdparray", this.pdparray)
      this.years = this.pdparray.map((item) => {
        return item.year
      })
      this.emppdpdata = this.pdparray[0]
      if (this.emppdpdata.status == "Half Yearly Open") this.h1flag = true
      else if (this.emppdpdata.status == "Year End Open") this.h2flag = true
      if (this.emppdpdata.status == "Half Yearly Open" || this.emppdpdata.status == "Year End Open")
        this.objectivesflag = true

      if (this.h1flag == true || this.h2flag == true || this.objectivesflag == true) this.saveenable = true
      this.yearindex = 0

      console.log("flags", this.h1flag, this.h2flag, this.objectivesflag)
    }, // end async initialize

    setyear(year) {
      console.log("setyear", year)
      this.h1flag = false
      this.h2flag = false
      this.objectivesflag = false
      this.saveenable = false
      this.yearindex = this.pdparray.findIndex((obj) => obj.year == year)
      var tmpemppdpdata = this.pdparray.filter((re) => re.year == year)
      this.emppdpdata = tmpemppdpdata[0]

      console.log("flags", this.h1flag, this.h2flag, this.objectivesflag)
      console.log("this.emppdpdata.status", this.emppdpdata.status)
      if (this.emppdpdata.status == "Half Yearly Open") this.h1flag = true
      else if (this.emppdpdata.status == "Year End Open") this.h2flag = true
      if (this.emppdpdata.status == "Half Yearly Open" || this.emppdpdata.status == "Year End Open")
        this.objectivesflag = true
      if (this.h1flag == true || this.h2flag == true || this.objectivesflag == true) this.saveenable = true
      console.log("flags", this.h1flag, this.h2flag, this.objectivesflag)
      console.log("emppdpdata", this.emppdpdata)
    }, //end of setyear
    async savepdp() {
      console.log("inside savpdp", this.yearindex)
      if (this.yearindex > -1) {
        this.spinner = true
        this.valid = false
        this.saveenable = false
        this.emppdpdata.reviewerstatus = "Updated"
        this.emppdpdata.modifiedon = new Date().toISOString().substring(0, 10)
        this.pdparray[this.yearindex] = Object.assign({}, this.emppdpdata)
        console.log("final pdparray", this.pdparray)
      
        var updatepdpdata = {}
        updatepdpdata.email = this.email
        updatepdpdata.reviewerstatus = "updated"
        updatepdpdata.pdp = this.pdparray
        let Ret = await this.updatePDP(updatepdpdata)
        console.log("From updatePDP", this.email, Ret)
        this.valid = true
        this.spinner = false
        this.saveenable = true
        if (Ret == true) {
          this.snackbar = true
          this.snackbartext = "The Data has been updated successfully"
        } else {
          this.alert = true
          this.alertmsg = "Error while update" + Ret
        }
      }
    },
  }, // end of method
}
</script>
<style scoped>
.tiptap-vuetify-editor .ProseMirror {
  min-height: 350px !important;
}
.tiptap-vuetify {
  min-height: 350px !important;
}
</style>
