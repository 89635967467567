import axios from "axios"
import { updateClientPDPurl } from "../fb"

export const updateClientPDPMixin = {
  methods: {
    async updatePDP(body) {
      console.log("inside updatePDP", body)
      try {
        await axios.post(updateClientPDPurl, body)
        console.log("updatePDP:updatePDP sucessfully")
        return true
      } catch (err) {
        console.log(err)
        return err
      }
    }, // end of addUser method
  },
}
